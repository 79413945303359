import React, { useEffect } from 'react';
import '../styles/chatbot.css';

export default function ChatbotEmbed() {
  useEffect(() => {
    // Initialize chatbot
    (function(){
      if(!window.chatbase || window.chatbase("getState") !== "initialized") {
        window.chatbase = (...args) => {
          if(!window.chatbase.q) {
            window.chatbase.q = [];
          }
          window.chatbase.q.push(args);
        };
        window.chatbase = new Proxy(window.chatbase, {
          get(target, prop) {
            if(prop === "q") {
              return target.q;
            }
            return (...params) => target(prop, ...params);
          }
        });
      }

      const onLoad = function() {
        const script = document.createElement("script");
        script.src = "https://www.chatbase.co/embed.min.js";
        script.id = "fMlMuKCKkjv3dncTtIWGV";
        script.setAttribute('domain', 'www.chatbase.co');
        document.body.appendChild(script);
      };

      if(document.readyState === "complete") {
        onLoad();
      } else {
        window.addEventListener("load", onLoad);
      }
    })();

    // Cleanup
    return () => {
      const script = document.getElementById('fMlMuKCKkjv3dncTtIWGV');
      if (script && script.parentNode) {
        script.parentNode.removeChild(script);
      }
      // @ts-ignore
      delete window.chatbase;
    };
  }, []);

  return null;
}