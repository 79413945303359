import { createClient } from '@supabase/supabase-js';
import type { Database } from '../types/supabase';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

// Helper function to check if Supabase is properly configured
export const isSupabaseConfigured = () => {
  try {
    // Validate URL format
    if (supabaseUrl) new URL(supabaseUrl);
    return Boolean(supabaseUrl && supabaseAnonKey);
  } catch (error) {
    console.warn('Invalid Supabase URL format');
    return false;
  }
};

// Only create client if credentials are available and valid
export const supabase = isSupabaseConfigured()
  ? createClient<Database>(supabaseUrl!, supabaseAnonKey!)
  : null;

// Helper function to safely access supabase client
export const getSupabaseClient = () => {
  if (!supabase) {
    console.warn('Supabase is not configured. Please check your environment variables.');
    return null;
  }
  return supabase;
};